import Vue from 'vue'
import Vuex from 'vuex'
import repository from "../api/repository";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth: {
            state: {
                user: sessionStorage.user ? JSON.parse(sessionStorage.getItem('user')) : null,
            },
            getters: {
                user: state => state.user,
                authenticated: state => state.user !== null,
                role: state => state.user.role_id ? state.user.role_id : state.user.user.role_id,
                verified: state => state.user.email_verified_at ? state.user.email_verified_at : state.user.user.email_verified_at
            },
            mutations: {
                SET_USER(state, user) {
                    state.user = user;
                }
            },
            actions: {
                async login({ commit }, user) {
                    const { data } = await repository.login(user);
                    if (data.user && data.token) {
                        commit('SET_USER', data);
                        sessionStorage.user = JSON.stringify(data);
                        sessionStorage.token = data.token;
                    } else {
                        return {
                            error: true,
                            data
                        };
                    }
                },
                async logout({ commit }) {
                    await repository.logout();
                    commit('SET_USER', null);
                    sessionStorage.removeItem('user');
                },
                async resetPassword({ commit }, forgotEmail) {
                    const { data } = await repository.resetPassword({
                        email: forgotEmail
                    })

                    if (data.success) {
                        return data
                    } else if (data.error) {
                        return {
                            error: true,
                            data
                        }
                    }
                },
                async setNewPassword({ commit }, params) {
                    const { data } = await repository.setNewPassword(params)

                    if (data.success) {
                        return data
                    } else if (data.error) {
                        return {
                            error: true,
                            data
                        }
                    }
                }
            }
        },
        general: {
            state: {
                petro_price: 0,
            },
            getters: {
                petro_price: state => state.petro_price,
            },
            mutations: {
                SET_PETRO_PRICE(state, price) {
                    state.petro_price = price;
                }
            },
            actions: {
                async getPetroPrice() {
                    const API_URL = process.env.VUE_APP_API_URL;

                    try {
                        let { data } = await axios.get(`${API_URL}/api/petro`);
                        this.commit('SET_PETRO_PRICE', data.data.price);
                    } catch (e) {
                        return {
                            success: false,
                            message: e.message
                        }
                    }

                    // let dataRequest = {
                    //     coins: ["PTR"],
                    //     fiats: ["Bs"]
                    // }

                    // try {
                    //     let { data } = await axios.post(`https://petroapp-price.petro.gob.ve/price`, dataRequest);
                    //     this.commit('SET_PETRO_PRICE', data.data.PTR.BS);
                    // } catch (e) {
                    //     return {
                    //         success: false,
                    //         message: e.message
                    //     }
                    // }
                },
            }
        }
    }
});