<template>
  <v-app id="top">
    <Alerts ref="Alerts"/>
    <v-main>
      <Navbar />
      <router-view/>
    </v-main>
    <Footer v-if="!authenticated"/>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    Navbar: () => import(/* webpackPrefetch: true */ '@/components/ui/Navbar'),
    Alerts: () => import(/* webpackPrefetch: true */ '@/components/ui/Alerts'),
    Footer: () => import(/* webpackPrefetch: true */ '@/components/ui/Footer'),
  },
  mounted () {
    let a = this.$refs;
    setTimeout(() => {
      this.$root.Alerts = a.Alerts;
    },1000)
  },
  computed: {
    ...mapGetters({
      authenticated: 'authenticated',
    })
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500&display=swap');

#app, h1, h2, h3, h4, h5, h6, p, span, ul, li, a, b, label {
  font-family: 'Nunito', sans-serif !important;
}

.font-20 {
  font-size: 20px;
}
</style>
