export const isRequired = (value) =>{
    return value ? true : false;
}

export const isNumber = (value) =>{
    return typeof value == "number" ? true : false;
}

export const isEmail = (value) =>{
    return /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(value) ? true : false;
}

export const isPassword = (value) =>{
    if(value.length >= 8)
			{		
				var mayuscula = false;
				var minuscula = false;
				var numero = false;
				var caracter_raro = false;
				
				for(var i = 0;i<value.length;i++)
				{
					if(value.charCodeAt(i) >= 65 && value.charCodeAt(i) <= 90)
					{
						mayuscula = true;
					}
					else if(value.charCodeAt(i) >= 97 && value.charCodeAt(i) <= 122)
					{
						minuscula = true;
					}
					else if(value.charCodeAt(i) >= 48 && value.charCodeAt(i) <= 57)
					{
						numero = true;
					}
					else
					{
						caracter_raro = true;
					}
				}
				if(mayuscula == true && minuscula == true && caracter_raro == true && numero == true)
				{
					return true;
				}
			}
			return false;
}