import api from "./api";

const API_URL = process.env.VUE_APP_API_URL || 'http://10.10.10.103';

export default {
    setNewPassword(params) {
        return api.post(`${API_URL}/reset/password`, params);
    },

    resetPassword(params) {
        return api.get(`${API_URL}/reset/password`, { params });
    },

    login(params) {
        return api.post(`${API_URL}/login`, params);
    },

    logout() {
        return api.get(`${API_URL}/logout`);
    },

    getServices() {
        return api.get(`${API_URL}/api/services`);
    },

    serviceRequestActive() {
        return api.get(`${API_URL}/api/requests/active`);
    },

    createService(params) {
        return api.post(`${API_URL}/api/services`, params);
    },

    updateService(id, params) {
        delete params.id;
        return api.post(`${API_URL}/api/services/${id}`, params);
    },

    deleteService(id) {
        return api.delete(`${API_URL}/api/services/${id}`);
    },

    getUser() {
        return api.get(`${API_URL}/api/user`);
    },

    getClients(id) {
        return api.get(`${API_URL}/api/clients/${id}`);
    },

    getMyRequests() {
        return api.get(`${API_URL}/api/requests/me`);
    },

    getUsers() {
        return api.get(`${API_URL}/api/users`);
    },

    deleteUser(id) {
        return api.get(`${API_URL}/api/users/destroy/${id}`);
    },

    setStatusUser(id, status) {
        return api.get(`${API_URL}/api/users/${status}/${id}`);
    },

    setRoleUser(user) {
        return api.post(`${API_URL}/api/users/updateRole`, user);
    },

    getRequestManage() {
        return api.get(`${API_URL}/api/requests/manage`);
    },

    saveResponse(id, action) {
        return api.get(`${API_URL}/api/requests/save/${action}/${id}`);
    },

    saveClient(params) {
        return api.post(`${API_URL}/api/save-client`, params);
    },

    deleteClient(id) {
        return api.get(`${API_URL}/api/clients/destroy/${id}`);
    },

    updateUser(params) {
        return api.post(`${API_URL}/api/users/updateUser`, params);
    },

    getRequestsByResponse() {
        return api.get(`${API_URL}/api/requests/pending`);
    },

    getUserByRequest(id) {
        return api.get(`${API_URL}/api/users/getByRequest/${id}`);
    },

    getRequestsByReview() {
        return api.get(`${API_URL}/api/requests/review`);
    },

    getAllRequests() {
        return api.get(`${API_URL}/api/requests/all`);
    },

    createUser(params) {
        return api.post(`${API_URL}/api/user`, params);
    },

    createServiceRequest(params) {
        return api.post(`${API_URL}/api/service-request`, params);
    },

    getFiles(id) {
        let url = id ? `${API_URL}/api/get-files/${id}` : `${API_URL}/api/get-files`
        return api.get(url);
    },

    getClientFiles(id) {
        return api.get(`${API_URL}/api/clients/get-files/${id}`);
    },

    getFilesByUser(userId) {
        return api.get(`${API_URL}/api/get-files/${userId}`);
    },

    getRoles() {
        return api.get(`${API_URL}/api/roles`);
    },

    downloadFile(url) {
        let params = {
            url
        }

        return api.post(`${API_URL}/api/download-file`, params);
    },

    deleteFile(data) {

        return api.post(`${API_URL}/api/delete-file`, data);
    },

    updateCorrelativo(params) {
        return api.post(`${API_URL}/api/requests/updateCorrelative`, params);
    },

    sendReminder(id) {
        return api.get(`${API_URL}/api/send-reminder/${id}`);
    },

    saveDates(id, data) {
        return api.post(`${API_URL}/api/requests/${id}/save-dates`, data);
    },

    saveHour(id, data) {
        return api.post(`${API_URL}/api/requests/${id}/save-hour`, data);
    },
    saveClientDate(id, data) {
        return api.post(`${API_URL}/api/requests/${id}/save-client-date`, data);
    },
    sendFinish(id, data) {
        return api.post(`${API_URL}/api/requests/${id}/finish`, data);
    },
    downloadPDF(id) {
        return api.get(`${API_URL}/api/serviceRequest/download/${id}`);
    },
    getCardsUpper() {
        return api.get(`${API_URL}/api/statistics/cardsUpper`);
    },
    getRequestsByStatus() {
        return api.get(`${API_URL}/api/statistics/requestsByStatus`);
    },
    getRequestsByYear() {
        return api.get(`${API_URL}/api/statistics/requestsByYear`);
    },
    getIncomeByYear() {
        return api.get(`${API_URL}/api/statistics/incomeByYear`);
    },
    getMostRequestedServices() {
        return api.get(`${API_URL}/api/statistics/mostRequestedServices`);
    },
    getPetroPrice() {
        return api.get(`${API_URL}/api/petro`);
    },
    getFileToSign(id) {
        return api.get(`${API_URL}/api/requests/generate-to-sign-file/${id}`);
    },
    saveFileSign(params) {
        return api.post(`${API_URL}/api/requests/save-sign-file`, params);
    },
    savePetroPrice(data) {
        console.log(data);
        let price = {petroPrice: data}
        return api.post(`${API_URL}/api/petro`, price);
    }
    // getPetroPrice() {
    //     let data = {
    //         coins: [
    //             "PTR"
    //         ],
    //         fiats: [
    //             "Bs"
    //         ]
    //     }
    //     return api.post(`https://petroapp-price.petro.gob.ve/price`, data);
    // },

};