import Vue from 'vue'
import routes from "./routes";
import Router from 'vue-router'

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: '/',
    fallback: false,
    scrollBehavior (to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
                top: 10000,
            }
        }
        return { x: 0, y: 0 };  // Go to the top of the page if no hash
    },
    routes
});
