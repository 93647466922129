import middleware from './middleware';

export default [{
        path: '/registrar-signatario/:id',
        props: true,
        name: 'formSignatory',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/signatory/signatory'),
    },
    {
        path: '/',
        name: 'home',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/home/home'),
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/auth/login'),
        beforeEnter: middleware.guest
    },
    {
        path: '/logout',
        name: 'logout',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/home/home'),
    },
    {
        path: '/forgotPass',
        name: 'forgotPass',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/auth/forgotPass'),
    },
    {
        path: '/recoverPass/:remember_token',
        props: true,
        name: 'recoverPass',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/auth/recoverPass'),
    },
    {
        path: '/register',
        name: 'register',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/auth/register'),
        beforeEnter: middleware.guest
    },
    {
        path: '/verificar-email',
        name: 'verificar-email',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/auth/verifyEmail'),
        beforeEnter: middleware.user,
    },

    //USER AUTHENTICATED
    {
        path: '/solicitud-servicios',
        name: 'solicitud-servicios',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/serviceRequests/servicesRequest/index'),
        //beforeEnter: middleware.user,
    },
    {
        path: '/mis-solicitudes',
        name: 'mis-solicitudes',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/serviceRequests/myRequestList/index'),
        //beforeEnter: middleware.user,
    },
    {
        path: '/responder-solicitudes',
        name: 'responder-solicitudes',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/serviceRequests/requestByResponse/index'),
        // beforeEnter: middleware.user,
    },
    {
        path: '/listado-solicitudes',
        name: 'listado-solicitudes',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/serviceRequests/requestList/index'),
        // beforeEnter: middleware.user,
    },
    {
        path: '/revisar-solicitudes',
        name: 'revisar-solicitudes',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/serviceRequests/reviewRequest/index'),
        // beforeEnter: middleware.user,
    },
    {
        path: '/servicios',
        name: 'servicios',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/services/serviceList/index'),
        // beforeEnter: middleware.user,
    },
    {
        path: '/usuarios',
        name: 'usuarios',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/users/index'),
        //beforeEnter: middleware.user,
    },
    {
        path: '/gestion-solicitudes',
        name: 'gestion-solicitudes',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/serviceRequests/manageRequest/index'),
        //beforeEnter: middleware.user,
    },
    {
        path: '/estadisticas',
        name: 'estadisticas',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/statistics/index'),
        // beforeEnter: middleware.user,
    },
    {
        path: '/perfil',
        name: 'profile',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/profile/index'),
        //beforeEnter: middleware.user,
    },
    {
        path: '/petro',
        name: 'petro',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/petro/index'),
        //beforeEnter: middleware.user,
    },
    {
        path: '/firmar',
        name: 'firmar',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/documentSign/index'),
        //beforeEnter: middleware.user,
    }
]