export const showOption = (option) => {
  switch (option) {
    case 'show':
      return {
          theme: "dark",
          icon: "icon-person",
          position: "topCenter",
          progressBarColor: "#f60261"
      }
    case 'center':
      return {
        position: "topCenter"
      }
    
    
  }
}