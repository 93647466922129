import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import moment from 'moment'

import vuetify from './plugins/vuetify'
Vue.use(Vuetify)

import VueIziToast from 'vue-izitoast';
import { showOption } from './functions/toast';
import "izitoast/dist/css/iziToast.css";
Vue.use(VueIziToast);

import * as validations from './functions/validations'
import * as utils from './functions/utils'

Vue.config.productionTip = false

const deviceType = () => {

    let desktop = true
    const ua = navigator.userAgent;
    if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return !desktop;
    }

    return desktop;
};

Vue.prototype.moment = moment
Vue.prototype.$utils = utils;
Vue.prototype.$validations = validations;
Vue.prototype.$device = deviceType();
Vue.prototype.$showOption = showOption;


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')